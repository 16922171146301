import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"
import { useRoutes } from "../../../hooks/useRoutes"
import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlistItem = Component => ({ name = `WishlistItem`, condensed, item, setZoom, setZoomURL }) => {
  const { activeCart, setActiveCart } = useApp()
  const { linkResolver } = useRoutes()
  const { moveToCart, deleteFromWishlist, loading } = useWishlist()

  const locales = {
    ...useLanguage(`cart`),
    ...useLanguage(`brides`),
    ...useLanguage(`form`),
  }

  const image = item?.images?.[0] || item?.image
  const product = item?.list === `products`
  const variant = item?.variants?.find(({ id }) => id === item?.selectedVariant) || item?.variants?.[0]?.id

  const handleMove = async () => {
    await moveToCart(item?.id, variant)
    if (!activeCart) {
      setActiveCart(true)
    }
  }
  const handleRemove = async () => await deleteFromWishlist(item?.id)
  const handleZoom = image => {
    setZoom(true)
    setZoomURL(image)
  }

  Component.displayName = name
  return (
    <Component
      condensed={condensed}
      handleMove={handleMove}
      handleRemove={handleRemove}
      handleZoom={handleZoom}
      image={image}
      item={item}
      linkResolver={linkResolver}
      loading={loading}
      locales={locales}
      product={product}
    />
  )
}
