import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"
import { useSection } from "../../../hooks/useSection"
import { useSanity } from "../../../hooks/useSanity"
import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlistShared = Component => ({ name = `WishlistShared`, location, page }) => {
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { transformSection } = useSection()
  const { heroNormaliser } = useSanity()
  const { sharedWishlist, getSharedWishlist } = useWishlist(true)

  const content = {
    ...page,
    heroBanner: heroNormaliser(page?.heroBanner),
  }
  const hero = transformSection(content.heroBanner)
  const shared = location?.pathname?.includes(routes?.SAVED_SHARE) && location?.search?.includes(params?.wishlist)

  if (!shared && isBrowser) {
    navigate(routes.SAVED)
    return null
  }

  if (!sharedWishlist?.length) getSharedWishlist()

  Component.displayName = name
  return <Component hero={hero} wishlist={sharedWishlist} />
}
